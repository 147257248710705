import { PageProps } from 'gatsby'
import styled from 'styled-components'

import { Accent, TitleLarge } from '../../common/styles'
import MarkdownWrapper from '../../components/MarkdownWrapper'
import Layout from '../../layouts'

import { Container } from './PrivacySection.styles'

const Title = styled(TitleLarge)`
  margin-top: 28px;
  padding-right: 7px;
  padding-left: 7px;
`

const PrivacyText = styled.div`
  padding: 0 21px;
  margin-bottom: 35px;
  width: 100%;
`

const PrestationsSection = ({
  data: { strapiGlobal }
}: Pick<PageProps<Queries.PrivacyQuery>, 'data'>) => {
  const text = strapiGlobal?.confidentialite?.data?.confidentialite!

  return (
    <Layout smallNavBar>
      <Container>
        <Title>
          Politique de <Accent>Confidentialité</Accent>
        </Title>
        <PrivacyText>
          <MarkdownWrapper>{text}</MarkdownWrapper>
        </PrivacyText>
      </Container>
    </Layout>
  )
}

export default PrestationsSection
