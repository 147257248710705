import { graphql, PageProps } from 'gatsby'

import PrivacySection from '../sections/PrivacySection'

const PrestationsIndex = ({ data }: PageProps<Queries.Privacy>) => {
  return <PrivacySection data={data} />
}

export default PrestationsIndex

export const query = graphql`
  query Privacy {
    strapiGlobal {
      confidentialite {
        data {
          confidentialite
        }
      }
    }
  }
`
